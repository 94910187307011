import Wrapper from "../../layout/wrapper";
import { getWhiteLabelConfig } from "../../services/whitelabel-config";
import Pricing from "../home/pricing";
import WhiteAboutArea from "./white-about-area";
import WhiteCompanyCarousel from "./white-company-carousel";
import WhiteCta from "./white-cta";
import WhiteHeader from "./white-header";
import WhiteHeroSlider from "./white-hero-slider";
import WhiteImageGallery from "./white-image-gallery";
import WhitePricing from "./white-pricing";
import WhitePricingSingle from "./white-pricing-single";
import WhiteTestimonial from "./white-testimonial";
import { useDispatch } from "react-redux";
import { useEffect, memo, useState } from "react";
import { toast } from "react-toastify";
import { GET_WHITELABEL_CONFIG } from "../../redux/features/whitelabel-config-slice";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Thumbs, Controller, EffectFade } from "swiper";
import { Link } from "react-router-dom";
import { ABOUT_URL, HERO_URL, WHY_URL } from "../../layout/headers/header";
import { setDataInLocalStorage } from "../../browser-storage";

import {
  Academics,
  ArtDesign,
  Business,
  DataScience,
  Finance,
  HealthFitness,
  Lifestyle,
  Marketing,
  Music,
  Learners,
  CatDot,
} from "../../svg";

const DynamicHeight = 450;
const ImageWeightHeight = 400;
// Get the full hostname (including subdomain)
let fullHostname = window.location.hostname;
//console.log("Current fullHostname:", fullHostname);
// Split the hostname into an array using dots as separators
let hostnameParts = fullHostname.split(".");

// The subdomain is the first part of the hostname
let subdomain = hostnameParts[0];

//console.log("Current subdomain:", subdomain);
const category_data = [
  {
    id: 1,
    icon: <DataScience />,
    title: "ATS-Compliant Resume Services",
    subtitle:
      "Your resume is your first impression. Let us help you create a winning resume that sails through Applicant Tracking Systems, getting you noticed by the right employers.",
  },
  {
    id: 2,
    icon: <Business />,
    title: "Personal Branding & LinkedIn Profile Creation",
    subtitle:
      "Stand out in the digital crowd. We'll craft a compelling personal brand and a LinkedIn profile that opens doors to new opportunities.",
  },
  {
    id: 3,
    icon: <ArtDesign />,
    title: "Communication Skills",
    subtitle:
      "Effective communication is the key to success. Sharpen your skills with our expert guidance and reach new heights in your career.",
  },
  {
    id: 4,
    icon: <Lifestyle />,
    title: "Group Discussion Tips",
    subtitle:
      "Ace group discussions with confidence. We provide insider tips and strategies to help you shine in any group setting.",
  },
];

const WhiteLabledHome = () => {
  const dispatch = useDispatch();

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  //const [whitehero, setWhitehero] = useState();
  const [whitehero, setWhitehero] = useState({
    HighLightedTitleBefore: "",
    HighLightedTitle: "",
    HighLightedTitleAfter: "",
    HeroSubTitle: "",
    HeroBanner: "",
  });

  const [whiteaboutus, setWhiteaboutus] = useState({
    AboutMainTitle: "",
    AboutDescription: "",
    AboutImage: "",
  });
  const [whitewhyus, setWhitewhyus] = useState({
    WhyMainTitle: "",
    WhyDescription: "",
    WhyImage: "",
  });
  const [OurServiceMainTitle, setOurServiceMainTitle] = useState();
  const [OurServiceSubTitle, setOurServiceSubTitle] = useState();
  const [PricingMainTitle, setPricingMainTitle] = useState();
  const [DynamicPricingHeight, setDynamicPricingHeight] = useState(580);
  const [DynamicImageWeightHeight, setDynamicImageWeightHeight] = useState(250);
  const [DynamicImageWeightHeight1, setDynamicImageWeightHeight1] =
    useState(400);
  //used for number of columns in services grid
  const [DynamicWH1, setDynamicWH1] = useState(4);
  const [DynamicWH2, setDynamicWH2] = useState(220);
  const [whiteservices, setWhiteservices] = useState([]);
  const [whitepricing, setWhitepricing] = useState([]);

  const [Hostname, setHostname] = useState();

  useEffect(() => {
    const Hostname = window.location.hostname;
    setHostname(Hostname);
    //const Hostname = "yashyash.mygreenhorn.in";
    console.log("Hostname", Hostname);
    const res = getWhiteLabelConfig(Hostname)
      .then((data) => {
        console.log(data);
        const current_hero = data[0].Hero;
        const current_aboutus = data[0].About;
        const current_whyus = data[0].Why;
        console.log("current_whyus", current_whyus);
        const current_service_main_title = data[0].OurServiceMainTitle;
        const current_service_sub_title = data[0].OurServiceSubTitle;
        const current_pricing_main_title = data[0].PricingMainTitle;
        const current_services = data[0].OurServicesGrid;
        const current_pricing = data[0].Pricing;
        console.log("current_pricing", current_pricing);
        const DynamicPricingHeight = data[0].DynamicPricingHeight;
        const DynamicImageWeightHeight = data[0].DynamicImageWeightHeight;
        const DynamicImageWeightHeight1 = data[0].DynamicImageWeightHeight1;
        const DynamicWH1 = data[0].DynamicWH1;
        const DynamicWH2 = data[0].DynamicWH2;
        const DynamicWH3 = data[0].DynamicWH3;
        setWhitehero({
          HighLightedTitleBefore: current_hero?.HighLightedTitleBefore,
          HighLightedTitle: current_hero.HighLightedTitle,
          HighLightedTitleAfter: current_hero.HighLightedTitleAfter,
          HeroSubTitle: current_hero.HeroSubTitle,
          HeroBanner: current_hero.HeroBanner,
        });
        setWhiteaboutus({
          AboutMainTitle: current_aboutus.AboutMainTitle,
          AboutDescription: current_aboutus.AboutDescription,
          //AboutImage: current_aboutus.AboutImage,
        });
        setWhitewhyus({
          WhyMainTitle: current_whyus.WhyMainTitle,
          WhyDescription: current_whyus.WhyDescription,
          //WhyImage: current_whyus.AboutMainTitle,
        });
        setOurServiceMainTitle(current_service_main_title);
        setOurServiceSubTitle(current_service_sub_title);
        setPricingMainTitle(current_pricing_main_title);
        setWhiteservices(current_services);
        setWhitepricing(current_pricing);
        setDataInLocalStorage("whiteLabelPricing", current_pricing);
        setDynamicPricingHeight(DynamicPricingHeight);
        setDynamicImageWeightHeight(DynamicImageWeightHeight);
        setDynamicWH1(DynamicWH1);
        setDynamicWH2(DynamicWH2);
        dispatch(GET_WHITELABEL_CONFIG(data));
      })
      .catch((err) =>
        // toast.error(`Unable to get White label Data. Contact US `, {
        //   position: "top-left",
        // })
        console.log("White Error", err)
      );
  }, [Hostname]);
  return (
    <>
      <Wrapper>
        <section className="slider__area p-relative">
          <div className="slider__wrapper swiper-container">
            <div className="swiper-wrapper">
              <Swiper
                thumbs={{
                  swiper:
                    thumbsSwiper && !thumbsSwiper.destroyed
                      ? thumbsSwiper
                      : null,
                }}
                loop={true}
                spaceBetween={0}
                slidesPerView={1}
                freeMode={false}
                watchSlidesProgress={true}
                effect="fade"
                modules={[Controller, FreeMode, EffectFade, Thumbs]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div
                    className="single-slider slider__height slider__overlay d-flex align-items-center"
                    style={{
                      //     background: `url(${whitehero.HeroBanner})`,
                      background: `url(${HERO_URL})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      height: 600,
                    }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-xxl-7 col-xl-8 col-lg-9 col-md-9 col-sm-10 mt-200">
                          <div className="slider__content">
                            <h4
                              style={{ fontSize: 40 }}
                              className="slider__title"
                            >
                              {whitehero.HighLightedTitleBefore}{" "}
                              <span
                                style={{ fontSize: 40 }}
                                className="yellow-bg"
                              >
                                {whitehero.HighLightedTitle}{" "}
                                <img
                                  src="/assets/img/shape/yellow-bg.png"
                                  alt=""
                                />{" "}
                              </span>{" "}
                              {whitehero.HighLightedTitleAfter}
                            </h4>

                            <p style={{ fontSize: 20 }}>
                              {whitehero.HeroSubTitle}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </section>
        <section
          style={{ paddingBottom: 20 }}
          className={`about__area pt-20 pb-20`}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="pt-60">
                  <img
                    className=""
                    style={{
                      borderRadius: "5%",
                      width: `${DynamicImageWeightHeight}px`,
                      height: `${DynamicImageWeightHeight}px`,
                      float: "right",
                      marginRight: 20,
                    }}
                    src={ABOUT_URL}
                  />
                </div>
              </div>
              <div className="col-md-8">
                <div className="pt-25">
                  <div className="section__title-wrapper mb-25">
                    <h2 style={{ fontSize: 40 }} className="section__title">
                      {whiteaboutus.AboutMainTitle}
                    </h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: whiteaboutus.AboutDescription,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {whitewhyus.WhyMainTitle !== "" && whitewhyus.WhyDescription !== "" ? (
          <>
            <section style={{ paddingBottom: 50 }} className={``}>
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <div className="pt-25">
                      <div className="section__title-wrapper mb-25">
                        <h2 style={{ fontSize: 40 }} className="section__title">
                          {whitewhyus.WhyMainTitle}
                        </h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: whitewhyus.WhyDescription,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="pt-60">
                      <img
                        className=""
                        style={{
                          borderRadius: "5%",
                          width: `${DynamicImageWeightHeight}px`,
                          height: `${DynamicImageWeightHeight}px`,
                          float: "",
                          marginLeft: 20,
                        }}
                        src={WHY_URL}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : null}

        {OurServiceMainTitle ? (
          <>
            <section
              className="testimonial__area pt-30 pb-30"
              // style={{
              //   background: `url(/assets/img/shape/testimonial-bg-3.jpg)`,
              //   backgroundRepeat: "no-repeat",
              //   backgroundSize: "cover",
              //   backgroundPosition: "center",
              // }}
            >
              <div className="text-center">
                <h2 style={{ fontSize: "40px" }}>{OurServiceMainTitle}</h2>
              </div>
              {/* <div className="" style={{ paddingLeft: 10, paddingRight: 10 }}>
                <div className="row">
                  <div class="mt-5">
                    <div className="text-center">
                      <p style={{ color: "white", fontSize: "20px" }}>
                        {OurServiceSubTitle}
                      </p>
                    </div>

                    <div class="row">
                      {whiteservices.map((item) => {
                        return (
                          <div className={`col-md-${DynamicWH1} mb-4`}>
                            <div
                              style={{ height: `${DynamicWH2}px` }}
                              class="card"
                            >
                              <div class="card-body">
                                <h5
                                  style={{ fontSize: "20px" }}
                                  class="card-title"
                                >
                                  {item.GridTitle}{" "}
                                </h5>
                                <p
                                  style={{ fontSize: "16px" }}
                                  class="card-text"
                                >
                                  {item.GridDescription}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="container">
                <div className="row">
                  {whiteservices.map((category, index) => (
                    <div
                      key={category.id}
                      className={`col-md-${DynamicWH1} mb-4`}
                    >
                      <div
                        style={{
                          height: `${DynamicWH2}px`,
                          padding: 20,
                        }}
                        className="category__item text-center mt-10"
                      >
                        <div className="text-center">
                          <div className="category__icon mr-30">
                            <img
                              width={50}
                              src={`https://mygreenhorn.in/WhiteLabelAssets/${subdomain}/icons/${
                                index + 1
                              }.svg?version=${Date.now()}`}
                            />
                          </div>
                        </div>
                        <div className=" mb-30 transition-3 d-flex align-items-center mt-20">
                          <div className="category__content">
                            <h4 className="category__title">
                              {category.GridTitle}
                            </h4>
                            <p className="mt-10 pt-10">
                              {category.GridDescription}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </>
        ) : null}

        <section className="price__area">
          <div className="container pt-20">
            <div className="row">
              <div className="col-xxl-3 offset-xxl-4">
                <div className="section__title-wrapper mb-30 text-center">
                  <h2 className="section__title">
                    <span
                      style={{ fontSize: "40px" }}
                      className="yellow-bg yellow-bg-big"
                    >
                      {PricingMainTitle}
                      <img src="/assets/img/shape/yellow-bg.png" alt="" />
                    </span>
                  </h2>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="price__tab-content">
                  <div className="tab-content" id="nav-tabContent">
                    <div className="row">
                      {whitepricing.map((price, index) => (
                        <div
                          key={index}
                          className="col-xxl-3 col-xl-3 col-lg-3 col-md-3"
                        >
                          <div
                            className="price__item grey-bg mb-30 p-relative"
                            style={{
                              padding: "20px",
                              minHeight: `${DynamicPricingHeight}px`,
                            }}
                          >
                            <div className="price__head">
                              <h4 style={{ fontSize: "20px" }}>
                                {price.PricingTitle}
                              </h4>
                              <p style={{ fontSize: "18px" }}>
                                {" "}
                                {price.PricingSubTitle}
                              </p>
                            </div>
                            <div className="price__features mb-20 mt-20">
                              <ul>
                                {price.PricingFeatures.map((feature, index) => (
                                  <li key={index}>
                                    {!feature == "" ? (
                                      <>
                                        <i className="far fa-check"></i>
                                        <div>{feature.value}</div>
                                      </>
                                    ) : null}
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Link
                                to="/main-payment-page"
                                state={{ data: price }}
                                style={{
                                  position: "absolute",
                                  bottom: 10,
                                  //left: 24,
                                }}
                                className={`e-btn e-btn-border`}
                              >
                                Get Started
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <WhiteCta /> */}
      </Wrapper>
    </>
  );
};

export default WhiteLabledHome;
